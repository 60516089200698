import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/home',
    // component: Home,
    children:[
      {
        path: 'home',// 首页
        name: 'home',
        component: Home
      },
      {
        path: 'broadcast',// 跨境电商直播
        name: 'broadcast',
        component: () => (import('@/views/Solution/broadcast.vue'))
      },
      {
        path: 'chatGPT',// chatGPT
        name: 'chatGPT',
        component: () => (import('@/views/Solution/chatGPT.vue'))
      },
      {
        path: 'guide', // 操作指南
        name: 'guide',
        component: () => (import('@/views/Guide/guide.vue'))
      },
      {
        path: 'newActivity', // 最新活动
        name: 'newActivity',
        component: () => import('@/views/NewActivity/newActivity.vue')
      },
      {
        path: 'about', // 关于我们
        name: 'about',
        component: () => import('@/views/About/aboutWe.vue')
      }
    ]
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 切换页面始终滚动到顶部
    // if(to.name == 'home' && to.query.goPage == '下载'){
    //   return { top: document.documentElement.scrollHeight - window.innerHeight - 500 }
    // }else 
    return { top: 0 }
  },
})

// router.beforeEach((to, from, next) => {
//   // ...
//   // 返回 false 以取消导航
//   // console.log('to', navigator.userAgent)
//   let userAgentInfo = navigator.userAgent;
//   let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
//   let getArr = Agents.filter(i => userAgentInfo.includes(i));
//   if(getArr.length > 0){
//     // window.location.href = 'http://www.baidu.com'
//   }else{
//     next()
//   }
// })

export default router
