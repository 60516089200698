<template>
    <div class="container">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</template>
<style lang="scss" scoped>
.container{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    span{
        position: absolute;
        top: 50%;
        left: 50%;
        width:4px;
        height: 4px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 0 3px rgba(255, 255, 255, .1),
        0 0 8px 5px rgba(255, 255, 255, .1),
        0 0 20px 2px rgba(255, 255, 255, 1);
        animation: liuxingyu 3s linear infinite;
        opacity: 0;
    }
    span::before{
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 200px;
        height: 1px;
        background: linear-gradient(90deg, #fff, transparent);
    }
    span:nth-child(1){
        top: 20px;
        right: 1000px;
        left: initial;
        animation-delay: .2s;
        animation-direction: 2s;
    }
    span:nth-child(2){
        top: 0;
        right: 1500px;
        left: initial;
        animation-delay: 1s;
        animation-direction: 2s;
    }
    span:nth-child(3){
        top: 0;
        right: 200px;
        left: initial;
        animation-delay: 3s;
        animation-direction: 1s;
    }
    span:nth-child(4){
        top: 0;
        right: 200px;
        left: initial;
        animation-delay: 5s;
        animation-direction: 1s;
    }
    span:nth-child(5){
        top: 0;
        right: 700px;
        width: 10px;
        height: 10px;
        left: initial;
        animation-delay: 0s;
        animation-direction: 5s;
    }
    span:nth-child(6){
        top: 30px;
        right: 250px;
        left: initial;
        animation-delay: 2s;
        animation-direction: 5s;
    }
    span:nth-child(7){
        top: 60px;
        right: 315px;
        left: initial;
        animation-delay: 1s;
        animation-direction: 5s;
    }
    span:nth-child(8){
        top: 430px;
        right: 159px;
        left: initial;
        animation-delay: 5s;
        animation-direction: 5s;
    }
    span:nth-child(9){
        top: 300px;
        right: 0px;
        left: initial;
        animation-delay: 1s;
        animation-direction: 5s;
    }
    span:nth-child(10){
        top: 400px;
        right: 0px;
        left: initial;
        animation-delay: .2s;
        animation-direction: 5s;
    }

    @keyframes liuxingyu {
        0%{
            transform: rotate(315deg) translateX(0);
            opacity: 1;
        }
        100%{
            transform: rotate(315deg) translateX(-1000px);
            opacity: 0;
        }
    }
}
</style>