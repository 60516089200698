<template>
    <div class="certificate">
        <a href="https://beian.miit.gov.cn"><span class="hover">粤ICP备13071117号</span></a>
        <span> | 网站备案号：粤ICP备13071117号-2 | 经营许可证编号：A2.B1.B2-20195215 |</span>
        <img src="@/assets/img/public_security_icon.png"/>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010402000368"><span class="hover">44010402000424</span></a>
    </div>
</template>
<style lang="scss" scoped>
    .certificate{
        height: 42px;
        background-color: #333333;
        font-size: 14px;
        display: flex;
        align-items:  center;
        justify-content: center;
        span:nth-child(1){
            color: #a9a9a9;
        }
        span:nth-child(2){
            color: #8b919d;
        }
        span:nth-child(4){
            color: #a9a9a9;
        }
        .hover:hover{
            color: white;
            cursor: pointer;
        }
        a{text-decoration: none;}
    }
</style>